import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {H2Html, PHtml} from '@/components/shared/HtmlElements';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Section from '@/components/brochureV2/primitive/Section/Section';
import Eyebrow from '@/components/brochureV2/primitive/Eyebrow/Eyebrow';

const alignmentClasses: {[key: string]: string} = {
  center: 'text-center',
  left: 'text-left',
};

export interface SectionHeaderProps {
  alignment?: AlignmentEnum;
  colorScheme?: ColorSchemeEnum;
  heading?: string;
  kicker?: string;
  subhead?: string;
  className?: string;
  headingClass?: string;
  headingWrapperClass?: string;
  subheadingWrapperClass?: string;
  spacerClass?: string;
  subheadSpacerClass?: string;
  eyebrowClass?: string;
  sectionName?: string;
  id?: string;
}

/**
 * @deprecated Please use `SectionHeader` from `@/components/base/sections/SectionHeader/SectionHeader.tsx`
 */
export default function SectionHeader({
  alignment,
  className,
  spacerClass,
  subheadSpacerClass,
  colorScheme,
  eyebrowClass,
  heading,
  headingClass,
  headingWrapperClass,
  subheadingWrapperClass,
  kicker,
  subhead,
  id,
  ...props
}: SectionHeaderProps) {
  const currentAlignment = alignment || AlignmentEnum.Left;
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const alignmentClass = alignmentClasses[currentAlignment];
  const themeClass = sectionSchemeClasses[currentScheme];

  const headingWrapperStyles = cn('sm:w-3/4 md:w-5/6', {
    'mx-auto': currentAlignment === AlignmentEnum.Center,
  });
  const subheadingWrapperStyles = cn('sm:w-3/4 md:w-2/3', {
    'mx-auto': currentAlignment === AlignmentEnum.Center,
  });

  return (
    <Section
      sectionName="SectionHeader"
      className={cn(className, themeClass, alignmentClass)}
      {...props}
      id={id ? id : undefined}
    >
      <div className="container">
        <div className={twMerge(headingWrapperStyles, headingWrapperClass)}>
          <Spacer size="3xl" className={spacerClass} />
          {kicker && <Eyebrow className={eyebrowClass}>{kicker}</Eyebrow>}
          <H2Html className={twMerge('font-bold text-t2', headingClass)}>
            {heading}
          </H2Html>
        </div>
        <div
          className={twMerge(subheadingWrapperStyles, subheadingWrapperClass)}
        >
          {subhead && (
            <>
              <Spacer size="lg" className={subheadSpacerClass} />
              <PHtml className="text-body-lg">{subhead}</PHtml>
            </>
          )}
        </div>
      </div>
    </Section>
  );
}
